export default {
  LOG_TAG: 'logger',
  d(...params) {
    if (process.env.NODE_ENV !== 'production') {
      console.log(this.LOG_TAG, params);
    }
  },
  w(...params) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(this.LOG_TAG, params);
    }
  },
  isEmpty(str) {
    return !(str && str.trim().length);
  },
};
