import { createStore } from 'vuex'
import model from '@/model'

export const store = createStore({
  state: {
    windowWidth: window.innerWidth,
    loading: false,
    xlr8AgeGroup: {
      text: '',
      value: 0,
    },
    xlrYear: {
      text: '',
      value: 0,
    },
    xlr8Results: [],
    xlr8ResultSelected: model.xlr8(),
  },
  mutations: {
    setWindowWidth(state) {
      state.windowWidth = window.innerWidth;
    },
    setLoading(state, data) {
      state.loading = data;
    },
    setXlr8AgeGroup(state, data) {
      state.xlr8AgeGroup = data;
    },
    setXlr8Year(state, data) {
      state.xlrYear = data;
    },
    setXlr8Results(state, data) {
      state.xlr8Results = data;
    },
    setXlr8ResultSelected(state, data) {
      state.xlr8ResultSelected = data;
    },
  },
  actions: {
  },
  modules: {
  }
})
