<template>
  <div :class="{
    'page_layout': isWaikato,
    'page_layout_canterbury': isCanterbury
    }">
    <!-- navi bar -->
    <nav-bar></nav-bar>
    <!-- content -->
    <page-content class="content"></page-content>
  </div>
</template>
<script>

const LOG_TAG = 'page_layout'
import NavBar from "@/components/nav_bar"
import PageContent from "@/components/page_content"

export default {
  name: LOG_TAG,
  components: {
    NavBar,
    PageContent,
  },
  computed: {
    isWaikato() {
      return process.env.VUE_APP_BRAND === 'waikato';
    },
    isCanterbury() {
      return process.env.VUE_APP_BRAND === 'canterbury';
    }
  },
}
</script>

<style scoped>
.page_layout {
  height: 100vh;
  background-color: var(--background-blue-light);
  overflow-y: hidden;
}
.page_layout_canterbury {
  height: 100vh;
  background-color: var(--background-canterbury-black);
  overflow-y: hidden;
}
.content {
  overflow-y: auto; /* Hide scrollbars */
  height: 100%;
}
</style>
