<template>
  <div v-if="embedded">
    <div class="my-overlay-loader-background-embedded d-flex flex-column justify-content-center">
      <div>
        <div class="spinner-border text-light"
             :class="{
                'text-dark': dark
             }"
             style="font-size: 0.25em" role="status">
        </div>
        <div class="mt-2" style="font-size: 0.6em"
             :class="{
                'text-dark': dark
             }">Please wait...</div>
      </div>
    </div>
  </div>
  <div v-else class="my-overlay-host">
    <div class="my-overlay-loader-background-dark d-flex flex-column justify-content-center">
      <div>
        <div class="spinner-border text-light" style="font-size: 0.25em" role="status">
        </div>
        <div class="mt-2" style="font-size: 0.6em">Please wait...</div>
      </div>
    </div>
  </div>
</template>

<script>
const LOG_TAG = 'loader';

export default {
  name: LOG_TAG,
  props: {
    embedded: {
      type: Boolean,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return false;
      }
    },
    dark: {
      type: Boolean,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return false;
      }
    }
  },
  data() {
    return {};
  },
}
</script>

<style scoped>
</style>
