import model from "@/model"
const LOG_TAG = 'app_bloc';
export default {
  queryXlr8(ageGroup, gender, year, onSuccess, onError) {
    window.webapi.apiService.queryXlr8(
      ageGroup, gender, year,
      (response) => {
        window.logger.d(LOG_TAG, response);
        const dataList = [];
        response.forEach((data, index) => {
          dataList.push(model.xlr8(data).setRanking(index+1));
        })
        window.store.commit('setXlr8Results', dataList);
        onSuccess();
      },
      () => {
        onError();
      }
    )
  },
};
