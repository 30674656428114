import { createApp } from 'vue'
import { store } from '@/store'
import App from './App.vue'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './assets/css/global.css'
import webapi from '@/webapi';
import logger from '@/util/logger';
import Axios from 'axios'
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fas, faCloudDownload } from '@fortawesome/free-solid-svg-icons'

library.add(fas)
library.add(faCloudDownload)
dom.watch();

window.webapi = webapi;
window.store = store;
window.logger = logger;

Axios.defaults.headers.common.Accept = 'application/json';
Axios.interceptors.response.use((response) => {
  return response
}, function (error) {
  // Do something with response error
  const response = error.response
  const status = response.status
  if (status === 401) {
    //
  }
  return Promise.reject(response)
})
window.axios = Axios;

createApp(App)
  .use(store)
  .mount('#app')


