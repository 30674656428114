<template>
  <div id="app">
    <loader v-if="isLoading" style="z-index: 9999;"></loader>
    <page-popup v-if="hasXlr8ResultSelected" style="z-index: 9999;"></page-popup>
    <page-layout></page-layout>
  </div>
</template>

<script>
import Loader from "@/components/loader"
import PageLayout from '@/components/page_layout'
import PagePopup from '@/components/page_popup'

export default {
  name: 'App',
  components: {
    Loader,
    PageLayout,
    PagePopup,
  },
  computed: {
    storeState() {
      return this.$store.state;
    },
    isLoading() {
      return this.storeState.loading;
    },
    storeXlr8ResultSelected() {
      return this.storeState.xlr8ResultSelected;
    },
    hasXlr8ResultSelected() {
      return this.storeXlr8ResultSelected.isValid();
    },
  },
  watch: {
    storeXlr8Results: function(value) {
      const vm = this;
      vm.mTableColumnValue.setOptions(value);
    }
  },
  created() {
    window.addEventListener('resize', () => {
      window.store.commit('setWindowWidth');
    });
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
