<template>
  <div class="my-overlay-host" style="">
    <div class="my-overlay-loader-background-darker d-flex flex-column justify-content-center col-12"
    style="overflow-y: auto;"
    >
      <div class="fade-in-popup container col-10 l-content ps-4 pe-4 pt-4 pb-3"
           style="min-height: 30rem; background-color: white; text-align: start; margin-top: 8rem; margin-bottom: 2rem">
        <div class="d-flex flex-row justify-content-end">
          <div class="clickable" v-on:click="onClickClose">
            <i class="fa-solid fa-times"></i>
          </div>
        </div>
        <div class="fw-bold">Ranked: <span class="fw-normal">{{ storeXlr8ResultSelected.getRanking() }}</span></div>
        <div class="fw-bold">Name: <span class="fw-normal text-capitalize">{{storeXlr8ResultSelected.formattedName()}}</span></div>
        <div class="fw-bold">Club: <span class="fw-normal text-capitalize">{{storeXlr8ResultSelected.formattedClub()}}</span></div>
        <div class="fw-bold">Combined Points:
          <span class="fw-normal">
            {{ storeXlr8ResultSelected.formattedTotalPoints(storeXlr8AgeGroup.value) }}
          </span>
        </div>
        <!-- column label -->
        <div v-if="windowWidth > 414">
          <div class="d-flex flex-row mt-3 ps-2 pe-2 pt-3"
               :class="{
                'l-content-column-label': isWaikato,
                'l-content-column-label-canterbury': isCanterbury
               }"
               style="font-size: 0.85em">
            <div style="flex: 5; height: 2rem; text-align: center">{{mTableColumn.options[0].text}}</div>
            <div style="flex: 5; height: 2rem; text-align: center">{{mTableColumn.options[1].text}}</div>
            <div style="flex: 5; height: 2rem; text-align: center">{{mTableColumn.options[2].text}}</div>
            <div style="flex: 5; height: 2rem; text-align: center">{{mTableColumn.options[3].text}}</div>
            <div style="flex: 4; height: 2rem; text-align: center">{{mTableColumn.options[4].text}}</div>
            <div style="flex: 4; height: 2rem; text-align: center">{{mTableColumn.options[5].text}}</div>
          </div>
        </div>
        <!-- column value -->
        <div class="mt-2"></div>
        <div v-if="windowWidth > 414">
          <div class="d-flex flex-row justify-content-evenly l-content-column-value pe-2 ps-2"
               :class="{
                  'row-even': index % 2 === 0,
                  'row-odd': index % 2 !== 0
                }"
               v-for="(value, index) in mTableColumnValue.options" :key="index">
            <div class="row-item text-capitalize text-center" style="flex: 5; font-size: 0.9em">{{ value.formattedCategory() }}</div>
            <div class="row-item text-capitalize text-center" style="flex: 5; font-size: 0.8em">{{ value.formattedMeet() }}</div>
            <div class="row-item text-capitalize text-center" style="flex: 5; font-size: 0.85em">{{ value.formattedEvent() }}</div>
            <div class="row-item text-center" style="flex: 5; font-size: 0.9em">{{ value.formattedResult() }}</div>
            <div class="row-item text-center" style="flex: 4; font-size: 0.9em">{{ value.formattedPoints() }}</div>
            <div class="row-item text-center" style="flex: 4; font-size: 0.9em">{{ value.formattedCourse() }}</div>
          </div>
        </div>
        <div v-else>
          <div class="d-flex flex-column mt-2 l-content-column-value pt-2 pe-2 ps-2 clickable"
               v-for="(value, index) in mTableColumnValue.options" :key="index"
               :class="{
                  'row-even': index % 2 === 0,
                  'row-odd': index % 2 !== 0
                }">
            <div class="d-flex flex-row justify-content-between">
              <div class="fw-bold" style="font-size: 1em;">{{ value.formattedEvent() }}</div>
              <div class="fw-normal" style="font-size: 1em;">{{ value.formattedCourse() }}</div>
            </div>
            <div class="d-flex flex-row justify-content-between mt-1">
              <div class="fw-normal" style="font-size: 0.85em;">{{ value.formattedMeet() }}</div>
            </div>
            <div class="d-flex flex-row justify-content-end mt-2">
              <div class="fw-bold" style="font-size: 0.9em;">{{ value.formattedResult() }}</div>
            </div>
            <div v-if="index % 2 === 0" class="pe-2 ps-2" style="width: 100%; height: 1px; background-color: darkgrey"></div>
            <div v-else class="pe-2 ps-2" style="width: 100%; height: 1px; background-color: lightgrey"></div>
            <div class="d-flex flex-row justify-content-between mt-1 mb-1">
              <div class="fw-normal" style="font-size: 1em;">{{ value.formattedCategory(storeXlr8AgeGroup.value) }}</div>
              <div class="fw-bold" style="font-size: 1em;">{{ value.formattedPoints(storeXlr8AgeGroup.value) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

const LOG_TAG = 'page_popup';

import model from '@/model';

export default {
  name: LOG_TAG,
  props: {
    dark: {
      type: Boolean,
      default: function () {
        //{text: '', value: '', type: 'help', 'account'}
        return false;
      }
    }
  },
  data() {
    return {
      mTableColumn: {
        options: [
          {text: 'Record\nCategory'},
          {text: 'Meet'},
          {text: 'Event'},
          {text: 'Result'},
          {text: 'Points'},
          {text: 'Course'},
        ]
      },
      mTableColumnValue: {
        options: [],
        setOptions(data) {
          this.options = data;
          window.logger.d(LOG_TAG, 'setOptions', data);
        }
      },
      mXlr8Selected: model.xlr8(),
    };
  },
  computed: {
    storeState() {
      return this.$store.state;
    },
    windowWidth() {
      return this.storeState.windowWidth;
    },
    isLoading() {
      return this.storeState.loading;
    },
    storeXlr8AgeGroup() {
      return this.storeState.xlr8AgeGroup;
    },
    storeXlrYear() {
      return this.storeState.xlrYear;
    },
    storeXlr8ResultSelected() {
      return this.storeState.xlr8ResultSelected;
    },
    isWaikato() {
      return process.env.VUE_APP_BRAND === 'waikato';
    },
    isCanterbury() {
      return process.env.VUE_APP_BRAND === 'canterbury';
    }
  },

  mounted() {
    const vm = this;
    const ageGroup = vm.storeXlr8AgeGroup.value;

    vm.mXlr8Selected = vm.storeXlr8ResultSelected;
    vm.mTableColumnValue.setOptions([
      vm.mXlr8Selected.getDistanceEvent(ageGroup),
      vm.mXlr8Selected.getIMEvent(ageGroup),
      vm.mXlr8Selected.getFormEvent(ageGroup),
      vm.mXlr8Selected.getNextHighestEvent(ageGroup),
    ])
    window.logger.d(LOG_TAG, 'mounted', vm.mXlr8Selected.formattedName());
  },
  methods: {
    onClickClose() {
      window.store.commit('setXlr8ResultSelected', model.xlr8());
    }
  }
}
</script>

<style scoped>
.l-content {
  border-radius: 0.6rem;
}
.l-content-column-label {
  background-color: var(--background-blue);
  border-radius: 0.6rem 0.6rem 0.1rem 0.1rem;
  color: white;
  font-size: 0.95em;
  font-weight: 600;
}
.l-content-column-label-canterbury {
  background-color: var(--background-canterbury-red);
  border-radius: 0.6rem 0.6rem 0.1rem 0.1rem;
  color: white;
  font-size: 0.95em;
  font-weight: 600;
}
.l-content-column-value {
  min-height: 3.6rem;
  border-radius: 0.1rem;
  color: black;
  font-size: 0.9em;
  font-weight: 500;
}
.row-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.row-even {
  background-color: lightgrey;
}
.row-odd {
  background-color: darkgrey;
}
</style>
