import apiHelper from "@/webapi/api_helper"

// apis
const API_XLR8 = 'xlr8';

const PARAM_AGE = 'age';
const PARAM_GENDER = 'gender';
const PARAM_YEAR = 'year';

/*
https://kozzndoyn2.execute-api.ap-southeast-2.amazonaws.com/prod/sww/xlr8?age=10&gender=male&year=2022
 */

const LOG_TAG = 'api_service';
export default {
  queryXlr8(ageGroup, gender, year, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(process.env.VUE_APP_API_TARGET, API_XLR8);

    url = url.concat('?', PARAM_AGE, '=', ageGroup);
    url = url.concat('&', PARAM_GENDER, '=', gender);
    url = url.concat('&', PARAM_YEAR, '=', year);

    window.logger.d(LOG_TAG, 'queryXlr8', url);
    apiHelper.apiGet(
      url,
      (response) => {onResponseCb(response)},
      (error) => onErrorCb(error));
  },

}