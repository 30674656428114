<template>
  <div :class="{
    'page_layout': isWaikato,
    'page_layout_canterbury': isCanterbury
    }">
    <div class="fade-in-image"
         :class="{
          'l-image': isWaikato,
          'l-image-canterbury': isCanterbury
         }"
    >

    </div>

    <div class="container-fluid l-content"
         :class="{
            'pe-3 ps-3': windowWidth <= 414,
            'pe-5 ps-5': windowWidth > 414
            }">
      <!-- name search -->
      <div v-if="mTableColumnValue.options.length > 0" class="d-flex flex-row justify-content-end pe-0 ps-0">
        <div class="form-outline">
          <input type="text"
                 id="search-swimmer"
                 class="form-control" placeholder="Search swimmer"
          v-model="mNameFilter">
        </div>
        <div class="me-2"></div>
        <json-c-s-v
          :data="mTableColumnValue.export()"
          name="xlr8-export">
          <button class="btn btn-secondary"
                  style="font-weight: bold" type="button">
            <i class="fa-solid fa-cloud-download"></i></button>

        </json-c-s-v>
      </div>
      <!-- column label -->
      <div :class="{
            'hidden': windowWidth <= 414,
            }">
        <div class="d-flex flex-row justify-content-evenly mt-3 pt-2"
             :class="{
                'l-content-column-label': isWaikato,
                'l-content-column-label-canterbury': isCanterbury
             }"
             style="font-size: 0.8em">
          <div style="flex: 3; height: 2rem; text-align: center">{{mTableColumn.options[0].text}}</div>
          <div style="flex: 6; height: 2rem; text-align: center">{{mTableColumn.options[1].text}}</div>
          <div style="flex: 7; height: 2rem; text-align: center">{{mTableColumn.options[2].text}}</div>
          <div style="flex: 5; height: 2rem; text-align: center">{{mTableColumn.options[3].text}}</div>
          <div style="flex: 4; height: 2rem; text-align: center">{{mTableColumn.options[4].text}}</div>
          <div style="flex: 4; height: 2rem; text-align: center">{{mTableColumn.options[5].text}}</div>
          <div style="flex: 5; height: 2rem; text-align: center">{{mTableColumn.options[6].text}}</div>
          <div style="flex: 5; height: 2rem; text-align: center">{{mTableColumn.options[7].text}}</div>
          <div style="flex: 5; height: 2rem; text-align: center">{{mTableColumn.options[8].text}}</div>
          <div style="flex: 4; height: 2rem; text-align: center">{{mTableColumn.options[9].text}}</div>
        </div>
      </div>
      <div class="mt-3"></div>
      <!-- column value -->
      <div v-if="windowWidth > 414">
        <div class="d-flex flex-row justify-content-evenly mt-2 l-content-column-value clickable"
             style="font-size: 0.8em"
             v-for="(value, index) in mTableColumnValue.optionsFiltered" :key="index"
             v-on:click="onClickResult(index, value)">
          <div class="row-item text-capitalize" style="flex: 3;">{{ value.getRanking() }}</div>
          <div class="row-item text-capitalize" style="flex: 6;">{{ value.formattedName() }}</div>
          <div class="row-item text-capitalize" style="flex: 7;">{{ value.formattedClub() }}</div>
          <div class="row-item" style="flex: 5">{{ storeXlr8AgeGroup.text }}</div>
          <div class="row-item" style="flex: 4">{{ value.formattedTotalPoints(storeXlr8AgeGroup.value) }}</div>
          <div class="row-item" style="flex: 4">{{ value.getDistanceEvent(storeXlr8AgeGroup.value).formattedPoints() }}</div>
          <div class="row-item" style="flex: 5">{{ value.getIMEvent(storeXlr8AgeGroup.value).formattedPoints() }}</div>
          <div class="row-item" style="flex: 5">{{ value.getFormEvent(storeXlr8AgeGroup.value).formattedPoints() }}</div>
          <div class="row-item" style="flex: 5;">{{ value.getNextHighestEvent(storeXlr8AgeGroup.value).formattedPoints() }}</div>
          <div class="row-item" style="flex: 4;">{{ storeXlrYear.text }}</div>
        </div>
      </div>
      <div v-else>
        <div class="d-flex flex-column mt-2 l-content-column-value pt-2 pe-2 ps-2 clickable"
             v-for="(value, index) in mTableColumnValue.optionsFiltered" :key="index"
             v-on:click="onClickResult(index, value)">
          <div class="d-flex flex-row justify-content-between">
            <div style="font-size: 1em; font-weight: bold">{{ value.getRanking() }}. <span>{{value.formattedName()}}</span></div><span>{{value.getValidEvents(storeXlr8AgeGroup.value)}}/4</span>
          </div>
          <div class="d-flex flex-row justify-content-between">
            <div class="text-capitalize" style="font-size: 1em; font-weight: normal">{{ value.formattedClub() }}</div>
          </div>
          <separator-line></separator-line>
          <div class="d-flex flex-row justify-content-end mt-2 mb-1">
            <div class="fw-bold" style="font-size: 1em;">{{ value.formattedTotalPoints(storeXlr8AgeGroup.value) }}</div>
          </div>
        </div>
      </div>
      <div style="height: 12rem"></div>
    </div>
  </div>
</template>

<script>

const LOG_TAG = 'page_content';

import SeparatorLine from "@/components/separator_line";
import JsonCSV from 'vue-json-csv';

export default {
  name: LOG_TAG,
  components: {
    SeparatorLine,
    JsonCSV
  },
  props: {

  },
  data() {
    return {
      json_data: [
        {
          'name': 'Tony Peña',
          'city': 'New York',
          'country': 'United States',
          'birthdate': '1978-03-15',
          'phone': {
            'mobile': '1-541-754-3010',
            'landline': '(541) 754-3010'
          }
        },
        {
          'name': 'Thessaloniki',
          'city': 'Athens',
          'country': 'Greece',
          'birthdate': '1987-11-23',
          'phone': {
            'mobile': '+1 855 275 5071',
            'landline': '(2741) 2621-244'
          }
        }
      ],
      mTableColumn: {
        options: [
          {text: ''},
          {text: 'Name'},
          {text: 'Club'},
          {text: 'Age Group'},
          {text: 'Combined Points'},
          {text: 'Distance'},
          {text: 'Medley'},
          {text: 'Stroke'},
          {text: 'Next-Highest'},
          {text: 'Year'},
        ]
      },
      mTableColumnValue: {
        options: [],
        setOptions(data) {
          this.options = data;
          this.optionsFiltered = data;
        },

        optionsFiltered: [],
        setOptionsFiltered(data) {
          this.optionsFiltered = data;
        },
        export() {
          return this.options.map((option) => option.toExport());
        }
      },
      mNameFilter: null
    };
  },
  computed: {
    storeState() {
      return this.$store.state;
    },
    windowWidth() {
      return this.storeState.windowWidth;
    },
    storeXlr8AgeGroup() {
      return this.storeState.xlr8AgeGroup;
    },
    storeXlrYear() {
      return this.storeState.xlrYear;
    },
    storeXlr8Results() {
      return this.storeState.xlr8Results;
    },
    isWaikato() {
      return process.env.VUE_APP_BRAND === 'waikato';
    },
    isCanterbury() {
      return process.env.VUE_APP_BRAND === 'canterbury';
    }
  },
  watch: {
    storeXlr8Results: function(value) {
      const vm = this;
      vm.mNameFilter = '';
      vm.mTableColumnValue.setOptions(value);
    },
    mNameFilter: function(value) {
      const vm = this;
      const filtered = vm.mTableColumnValue.options.filter((option) => option.formattedName().toLowerCase().includes(value.toLowerCase()));
      vm.mTableColumnValue.setOptionsFiltered(filtered);
    }
  },
  mounted() {
    const vm = this;
    vm.mTableColumnValue.setOptions(vm.storeXlr8Results);
  },
  methods: {
    onClickResult(index, value) {
      window.logger.d(LOG_TAG, 'onClickResult', index, value.formattedName());
      window.store.commit('setXlr8ResultSelected', value);
    }
  }
}
</script>
<style scoped>
.page_layout {
  position: relative;
  background-color: var(--background-blue-light);
}
.page_layout_canterbury {
  position: relative;
  background-color: var(--background-canterbury-black);
}
.l-image {
  position: relative;
  width: 100%;
  height: 32rem;
  object-fit: cover;
  background-image: url("../assets/image/page_content_background.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.l-image-canterbury {
  position: fixed;
  width: 100%;
  height: 56rem;
  object-fit: cover;
  background-image: url("../assets/image/page_content_background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.l-content {
  position: absolute;
  width: 100%;
  top: 2rem;
}
.l-content-column-label {
  background-color: var(--background-black-p75);
  border-radius: 0.6rem;
  color: white;
  font-size: 0.95em;
  font-weight: 600;
}
.l-content-column-label-canterbury {
  background-color: var(--background-canterbury-red);
  border-radius: 0.6rem;
  color: white;
  font-size: 0.95em;
  font-weight: 600;
}
.l-content-column-value {
  min-height: 3.6rem;
  background-color: white;
  border-radius: 0.3rem;
  color: black;
  font-size: 0.9em;
  font-weight: 500;
}
.row-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}
</style>
