import model from "@/model"

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      memberId: '',
      xlrYear: '',
      gender: '',
      name: '',
      club: '',
      region: '',
      regionCode: 0,

      xlr8_10under: [],
      xlr8_10under_total: 0,
      xlr8_11: [],
      xlr8_11_total: 0,
      xlr8_12: [],
      xlr8_12_total: 0,
      xlr8_13over: [],
      xlr8_13over_total: 0,
      rankingIndex: 0
    };
  }
  object.toExport = function toExport() {
    return {
      xlrYear: object.xlrYear,
      gender: object.gender,
      name: object.name,
      club: object.club,
      region: object.region,

      xlr8_10under_total: object.xlr8_10under_total,
      xlr8_11_total: object.xlr8_11_total,
      xlr8_12_total: object.xlr8_12_total,
      xlr8_13over_total: object.xlr8_13over_total,
    }
  }
  object.isValid = function isValid() {
    return object.memberId.length > 0;
  }
  object.setRanking = function setRanking(index) {
    object.rankingIndex = index;
    return object;
  }
  object.formattedName = function formattedName() {
    return object.name ? object.name : '---';
  }
  object.formattedClub = function formattedClub() {
    return object.club ? object.club : '---';
  }
  object.getRanking = function getRanking() {
    return object.rankingIndex;
  }
  object.getPb10Under = function getPb10Under() {
    return object.xlr8_10under ? object.xlr8_10under.map((data) => model.pb(data)) : [];
  }
  object.getPb11 = function getPb11() {
    return object.xlr8_11 ? object.xlr8_11.map((data) => model.pb(data)) : [];
  }
  object.getPb12 = function getPb12() {
    return object.xlr8_12 ? object.xlr8_12.map((data) => model.pb(data)) : [];
  }
  object.getPb13Over = function getPb13Over() {
    return object.xlr8_13over ? object.xlr8_13over.map((data) => model.pb(data)) : [];
  }
  object.formattedPoints10Under = function formattedPoints10Under() {
    return object.xlr8_10under_total ? parseInt(object.xlr8_10under_total, 10) : '---';
  }
  object.formattedPoints11 = function formattedPoints11() {
    return object.xlr8_11_total ? parseInt(object.xlr8_11_total, 10) : '---';
  }
  object.formattedPoints12 = function formattedPoints12() {
    return object.xlr8_12_total ? parseInt(object.xlr8_12_total, 10) : '---';
  }
  object.formattedPoints13Over = function formattedPoints13Over() {
    return object.xlr8_13over_total ? parseInt(object.xlr8_13over_total, 10) : '---';
  }
  object.getPb = function getPb(ageGroup) {
    switch (ageGroup) {
      case 10:
        return object.getPb10Under();
      case 11:
        return object.getPb11();
      case 12:
        return object.getPb12();
      case 13:
        return object.getPb13Over();
      default:
        return [];
    }
  }
  object.getDistanceEvent = function getDistanceEvent(ageGroup) {
    const event = this.getPb(ageGroup).find((pb) => pb.isDistanceEvent());
    return event ? event : model.pb({
      category: 'distanceEvent'
    });
  }
  object.getIMEvent = function getIMEvent(ageGroup) {
    const event = this.getPb(ageGroup).find((pb) => pb.isIMEvent());
    return event ? event : model.pb({
      category: 'IMEvent'
    });
  }
  object.getFormEvent = function getFormEvent(ageGroup) {
    const event = this.getPb(ageGroup).find((pb) => pb.isFormEvent());
    return event ? event : model.pb({
      category: 'FormEvent'
    });
  }
  object.getNextHighestEvent = function getNextHighestEvent(ageGroup) {
    const event = this.getPb(ageGroup).find((pb) => pb.isNextHighestEvent());
    return event ? event : model.pb({
      category: 'NextHighestEvent'
    });
  }
  object.formattedTotalPoints = function formattedTotalPoints(ageGroup) {
    switch (ageGroup) {
      case 10:
        return object.formattedPoints10Under();
      case 11:
        return object.formattedPoints11();
      case 12:
        return object.formattedPoints12();
      case 13:
        return object.formattedPoints13Over();
      default:
        return '---';
    }
  }
  object.getValidEvents = function formattedEventSummary(ageGroup) {
    let validEvents = 0;
    if (object.getDistanceEvent(ageGroup).isValid()) {
      validEvents++;
    }
    if (object.getIMEvent(ageGroup).isValid()) {
      validEvents++;
    }
    if (object.getFormEvent(ageGroup).isValid()) {
      validEvents++;
    }
    if (object.getNextHighestEvent(ageGroup).isValid()) {
      validEvents++;
    }
    return validEvents;
  }
  return object;
}

