
export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      xlr8pts: 0,
      xlr8year: '',
      category: '',
      course: 0,
      result: '',
      meet: '',
      event: '',

    };
  }
  object.isDistanceEvent = function isDistanceEvent() {
    return this.category === 'distanceEvent';
  }
  object.isIMEvent = function isIMEvent() {
    return this.category === 'IMEvent';
  }
  object.isFormEvent = function isFormEvent() {
    return this.category === 'FormEvent';
  }
  object.isNextHighestEvent = function isNextHighestEvent() {
    return this.category === 'NextHighestEvent';
  }
  object.formattedCategory = function formattedCategory() {
    if (object.isDistanceEvent()) {
      return 'Distance';
    } else if (object.isIMEvent()) {
      return 'Medley';
    } else if (object.isFormEvent()) {
      return 'Form';
    } else if (object.isNextHighestEvent()) {
      return 'Next Highest';
    } else {
      return '---';
    }
  }
  object.formattedEvent = function formattedEvent() {
    return object.event ? object.event : '---';
  }
  object.formattedMeet = function formattedMeet() {
    return object.meet ? object.meet : '---';
  }
  object.formattedResult = function formattedResult() {
    return object.result ? object.result : '---';
  }
  object.formattedPoints = function formattedPoints() {
    return object.xlr8pts ? parseInt(object.xlr8pts, 10) : '---';
  }
  object.formattedCourse = function formattedCourse() {
    return object.course ? object.course === 25 ? 'S' : 'L' : '---';
  }
  object.isValid = function isValid() {
    return object.result;
  }
  return object;
}

